<template>
  <el-popover
    :placement="popPosition"
    width
    trigger="click"
    content="something here"
    popper-class="apps"
  >
    <ul class="apps__group apps__group--main">
      <li
        v-for="(app, index) in mainApps"
        :key="index"
        @click="openMenu(app)"
        class="apps__group__item"
      >
        <!-- content to trigger tooltip here -->

        <img class="apps__group__item--icon" :title="app.tooltip" :src="app.icon" />
        <div class="apps__group__item--label">{{ app.title }}</div>
      </li>
    </ul>

    <el-divider class="divider"></el-divider>

    <ul class="apps__group apps__group--cesar">
      <li
        v-for="(app, index) in cesarApps"
        :key="index"
        @click="openMenu(app)"
        class="apps__group__item"
      >
        <!-- content to trigger tooltip here -->

        <img class="apps__group__item--icon" :title="app.tooltip" :src="app.icon" />
        <div class="apps__group__item--label">{{ app.title }}</div>
      </li>
    </ul>

    <h2 class="apps__subtitle">Sistemas Parceiros</h2>

    <ul class="apps__group apps__group--external">
      <li
        v-for="(app, index) in externalApps"
        :key="index"
        @click="openMenu(app)"
        class="apps__group__item"
      >
        <!-- content to trigger tooltip here -->

        <img class="apps__group__item--icon" :title="app.tooltip" :src="app.icon" />
        <div class="apps__group__item--label">{{ app.title }}</div>
      </li>
    </ul>
    <div slot="reference" class="popover__wrapper">
      <span class="menu">
        <i class="el-icon-menu"></i>
      </span>
    </div>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      isAppsMenuOpen: true,
      popPosition: "bottom-end",
      mainApps: [
        {
          title: "Intranet",
          icon: require("../../assets/menu_apps/intranet.svg"),
          link: "http://intranetsite.cesar.org.br/",
          tooltip: "Intranet",
        },
        {
          title: "Portal do Colaborador",
          icon: require("../../assets/menu_apps/portal.svg"),
          link: "https://portalcolaborador.cesar.org.br/",
          tooltip: "Portal do Colaborador",
        },
        {
          title: "Zeev",
          icon: require("../../assets/menu_apps/zeev.svg"),
          link: "https://cesar.zeev.it//",
          tooltip: "Zeev",
        },
        {
          title: "Qulture",
          icon: require("../../assets/menu_apps/qulture_rocks.svg"),
          link: "https://app.qulture.rocks/",
          tooltip: "Qulture",
        },
      ],
      cesarApps: [
        {
          title: "Biblioteca",
          icon: require("../../assets/menu_apps/biblioteca.svg"),
          link: "http://portalacademico.cesar.edu.br/cesaredu/acadweb.acessolivre.php?acao=bokconsulta&dropmenu=true&#",
          tooltip: "Biblioteca",
        },
        {
          title: "Codi",
          icon: require("../../assets/menu_apps/codi.svg"),
          link: "https://codi.cesar.org.br/",
          tooltip: "Codi",
        },
        {
          title: "Indique um amigo",
          icon: require("../../assets/menu_apps/indique um amigo.svg"),
          link: "https://goo.gl/forms/zQkCdI1ggsNlHT4Y2",
          tooltip: "Programa indique um amigo",
        },
        {
          title: "Mapeamento de competências",
          icon: require("../../assets/menu_apps/mapeamento competencias.svg"),
          link: "https://forms.gle/9xoi3yQd7KXMdMoY9",
          tooltip: "Mapeamento de competências",
        },
        {
          title: "Metas",
          icon: require("../../assets/menu_apps/metas.svg"),
          link: "https://metas.cesar.org.br/",
          tooltip: "Metas",
        },
        {
          title: "Mobilidade",
          icon: require("../../assets/menu_apps/mobilidade.svg"),
          link: "https://forms.gle/KB3hPxP3GHZVeNMN8",
          tooltip: "Mobilidade",
        },
        {
          title: "Papeletas",
          icon: require("../../assets/menu_apps/papeleta.svg"),
          link: "https://papeletas.cesar.org.br/",
          tooltip: "Papeletas",
        },
        {
          title: "Portfólio",
          icon: require("../../assets/menu_apps/portifolio.png"),
          link: "https://portfolio.cesar.org.br/",
          tooltip: "Portfólio",
        },
        {
          title: "PrestContas",
          icon: require("../../assets/menu_apps/prestcontas.svg"),
          link: "https://prestcontas.cesar.org.br/",
          tooltip: "PrestContas",
        },
        {
          title: "Rateio",
          icon: require("../../assets/menu_apps/rateio.svg"),
          link: "http://rateio.cesar.org.br/",
          tooltip: "Rateio",
        },
        {
          title: "Segurança da Informação",
          icon: require("../../assets/menu_apps/infosegurança.png"),
          link: "http://infosec.cesar.org.br/",
          tooltip: "Segurança da Informação",
        },
        {
          title: "Portal de Viagens",
          icon: require("../../assets/menu_apps/portal_viagens.svg"),
          link: "https://viagens.cesar.org.br/",
          tooltip: "Portal de Viagens",
        },
        {
          title: "Carreiras",
          icon: require("../../assets/menu_apps/site_carreiras.svg"),
          link: "https://carreiras.cesar.org.br/",
          tooltip: "Carreiras",
        },
      ],
      externalApps: [
        {
          title: "Breezy",
          icon: require("../../assets/menu_apps/breezy.svg"),
          link: "https://app.breezy.hr/signin",
          tooltip: "Breezy",
        },
        {
          title: "CRM",
          icon: require("../../assets/menu_apps/crm.svg"),
          link: "https://cesar.my.salesforce.com/",
          tooltip: "CRM",
        },
        {
          title: "Desko",
          icon: require("../../assets/menu_apps/desko.png"),
          link: "https://cesar.desko.com.br/app/home",
          tooltip: "Desko",
        },
        {
          title: "HELP",
          icon: require("../../assets/menu_apps/help.png"),
          link: "https://cesar.verdanadesk.com/",
          tooltip: "HELP Central de Serviços",
        },
        {
          title: "Zeev",
          icon: require("../../assets/menu_apps/zeev.svg"),
          link: "https://cesar.zeev.it//",
          tooltip: "Zeev",
        },
        {
          title: "Learning.    Rocks",
          icon: require("../../assets/menu_apps/learningrocks.png"),
          link: "https://cesar.learning.rocks/",
          tooltip: "Learning.Rocks",
        },
        {
          title: "Portal do Colaborador",
          icon: require("../../assets/menu_apps/portal.svg"),
          link: "https://portalcolaborador.cesar.org.br/",
          tooltip: "Portal do Colaborador",
        },
        {
          title: "Qulture",
          icon: require("../../assets/menu_apps/qulture_rocks.svg"),
          link: "https://app.qulture.rocks/",
          tooltip: "Qulture",
        },
        {
          title: "Qlik",
          icon: require("../../assets/menu_apps/qlik.svg"),
          link: "https://bi.cesar.org.br",
          tooltip: "Qlik",
        },
      ],
    };
  },
  mounted() {
    this.popPosition = this.isMobile ? "bottom" : "bottom-end";
  },
  methods: {
    openMenu: function (app) {
      const win = window.open(app.link, "_blank");
      win.focus();
    },
  },
};
</script>

<style lang="scss">
.popover__wrapper {
  display: flex;
  width: 26px;
  justify-content: center;
  align-items: center;
}

.menu {
  color: #4b4b4b;
  font-size: 18px;
  cursor: pointer;
}

span.menu:hover {
  color: #ff7b00;
  font-size: 22px;
  cursor: pointer;
}

.popover__content {
  position: absolute;
  top: 25px;
  right: 0;
  background-color: #bfbfbf;
  padding: 1.5rem;
}

.apps {
  width: 465px;
  max-height: 90%;
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 5px;

  &.el-popper[x-placement^="bottom"] {
    margin-top: 5px;
    margin-right: -20px;
  }

  &__group {
    margin: 0px 10px;
    padding: 0;

    &__item {
      width: 100px;
      margin: 7px 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &:hover {
        cursor: pointer;
        color: #e87305;
      }

      &--icon {
        width: 40px;
        height: 40px;
      }

      &--label {
        font-size: 14px;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        width: 92%;
        word-break: break-word;

        &:hover {
          text-overflow: unset;
          white-space: normal;
        }
      }
    }
  }
  &__subtitle {
    text-align: center;
    margin: 0.5em 0 0.5em;
    font-size: 18px;
    font-weight: normal;
  }

  .divider.el-divider {
    background-color: #f5f5f5;

    &--horizontal {
      margin: 12px;
      width: 95%;
      height: 2px;
    }
  }
}
</style>
